import React from "react";

import Header from "../components/Header";
import PageIllustration from "../partials/PageIllustration";
import Hero from "../components/Hero";
import Intro from "../components/Intro";
import About from "../components/About";
import Roadmap from "../components/Roadmap";
import Team from "../components/Team";
import Footer from "../components/Footer";
import FAQ from "../components/FAQ";

function Home(props) {
    const { selectedAddress } = props

    return (
        <div className="flex flex-col min-h-screen overflow-hidden">
            {/*  Site header */}
            <Header />

            {/*  Page content */}
            <main className="flex-grow">
                {/*  Page illustration */}
                <div className="relative max-w-6xl mx-auto h-0 pointer-events-none -z-1" aria-hidden="true">
                    <PageIllustration />
                </div>

                {/*  Page sections */}
                <Hero />
                <Intro selectedAddress={selectedAddress} />
                <About />
                <Roadmap />
                <FAQ />
                <Team />
                <Footer />
            </main>
        </div>
    );
}

export default Home;
