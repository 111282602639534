import React, { useState } from 'react';
import Rocket1 from '../../images/rocket_1.svg';
import Rocket2 from '../../images/rocket_2.svg';
import Rocket3 from '../../images/rocket_3.svg';


function Timeline() {

  const [percentage, setPercentage] = useState('5%');

  return (
    <section id="roadmap">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200 dark:border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 font-hemi mb-4">Roadmap</h2>
          </div>

          {/* Section content */}
          {/* Items */}
          <div className="max-w-sm mx-auto grid grid-cols-1 gap-4 gap-y-8 sm:gap-8 md:grid-cols-3 items-start md:max-w-5xl">

            {/* 1st item */}
            <div className="relative flex flex-col items-center">
              <div aria-hidden="true" className="absolute h-0.5 bg-gradient-to-r from-white via-gray-300 to-white dark:from-gray-900 dark:via-gray-700 dark:to-gray-900 hidden md:block" style={{ width: 'calc(100% - 96px)', left: 'calc(50% + 64px)', top: '40px' }}></div>
              <div className="relative w-20 h-20 mb-3">
                <div className="absolute inset-0 rounded-full opacity-30 bg-gradient-to-tr from-teal-500 -z-1" aria-hidden="true"></div>
                <img src={Rocket1} className="transform scale-75 " />
              </div>
              <div className="sm:text-lg font-medium dark:text-gray-300 text-center">Pre-sale</div>
              <div className="sm:text-lg font-medium dark:text-gray-300 text-center">
                Up to 7 ETH Worth in ETH, Prizes & Giveaways
              </div>
            </div>

            {/* 2nd item */}
            <div className="relative flex flex-col items-center">
              <div aria-hidden="true" className="absolute h-0.5 bg-gradient-to-r from-white via-gray-300 to-white dark:from-gray-900 dark:via-gray-700 dark:to-gray-900 hidden md:block" style={{ width: 'calc(100% - 96px)', left: 'calc(50% + 64px)', top: '40px' }}></div>
              <div className="relative w-20 h-20 mb-3">
                <div className="absolute inset-0 rounded-full opacity-30 bg-gradient-to-tr from-teal-500 -z-1" aria-hidden="true"></div>
                <img src={Rocket2} className="transform scale-75 " />
              </div>
              <div className="sm:text-lg font-medium dark:text-gray-300 text-center">Public Sale</div>
              <div className="sm:text-lg font-medium dark:text-gray-300 text-center">Up to 10 ETH Worth in ETH, Prizes & Giveaways</div>
            </div>

            {/* 3rd item */}
            <div className="relative flex flex-col items-center">
              <div className="relative w-20 h-20 mb-3">
                <div className="absolute inset-0 rounded-full opacity-30 bg-gradient-to-tr from-teal-500 -z-1" aria-hidden="true"></div>
                <img src={Rocket3}  className="transform scale-75 rotate-45" />
              </div>
              <div className="sm:text-lg font-medium dark:text-gray-300 text-center">Post sale</div>
              <div className="sm:text-lg font-medium dark:text-gray-300 text-center">Giveaways, community, merch, partnerships</div>
            </div>

          </div>
        </div>


        {/* Timeline */}

        <div className="p-4 mt-4">
          <div className="container">
            <div className="flex flex-col md:grid grid-cols-12 text-gray-50">

              <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                  <div className="h-full w-6 flex items-center justify-center">
                    <div className="h-full w-1 bg-white dark:bg-gray-800 pointer-events-none"></div>
                  </div>
                  <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-white dark:bg-gray-800 shadow text-center">
                  </div>
                </div>
                <div className="bg-white dark:bg-gray-800 col-start-4 col-end-12 p-4 my-4 mr-auto shadow-md w-full">
                  <h3 className="h4 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">
                    5 %
                  </h3>
                  <div className="text-gray-600 dark:text-gray-300 flex-grow font-hemi">
                    <p className="h5 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">Mints #201-700</p>
                    <p className="text-center">Every 500 mints for the pre-sale, holders will be entered into a draw to win 1 ETH. This will start from #201 onwards as 200 will be pre-minted for giveaways and collaborations. </p>
                  </div>
                </div>
              </div>

              <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                  <div className="h-full w-6 flex items-center justify-center">
                    <div className="h-full w-1 bg-white dark:bg-gray-800 pointer-events-none"></div>
                  </div>
                  <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-white dark:bg-gray-800 shadow text-center">
                    <i className="fas fa-check-circle text-white"></i>
                  </div>
                </div>
                <div className="bg-white dark:bg-gray-800 col-start-4 col-end-12 p-4 my-4 mr-auto shadow-md w-full">
                  <h3 className="h4 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">
                    10 %
                  </h3>
                  <div className="text-gray-600 dark:text-gray-300 flex-grow font-hemi">
                    <p className="h5 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">Mints #701-1200</p>
                    <p className="text-center">Second 1 ETH draw</p>
                  </div>
                </div>
              </div>

              <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                  <div className="h-full w-6 flex items-center justify-center">
                    <div className="h-full w-1 bg-white dark:bg-gray-800 pointer-events-none"></div>
                  </div>
                  <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-white dark:bg-gray-800 shadow text-center">
                    <i className="fas fa-check-circle text-white"></i>
                  </div>
                </div>
                <div className="bg-white dark:bg-gray-800 col-start-4 col-end-12 p-4 my-4 mr-auto shadow-md w-full">
                  <h3 className="h4 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">
                    15 %
                  </h3>
                  <div className="text-gray-600 dark:text-gray-300 flex-grow font-hemi">
                    <p className="h5 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">Mints #1201-1700</p>
                    <p className="text-center">Third 1 ETH draw</p>
                  </div>
                </div>
              </div>

              <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                  <div className="h-full w-6 flex items-center justify-center">
                    <div className="h-full w-1 bg-white dark:bg-gray-800 pointer-events-none"></div>
                  </div>
                  <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-white dark:bg-gray-800 shadow text-center">
                    <i className="fas fa-check-circle text-white"></i>
                  </div>
                </div>
                <div className="bg-white dark:bg-gray-800 col-start-4 col-end-12 p-4 my-4 mr-auto shadow-md w-full">
                  <h3 className="h4 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">
                    20 %
                  </h3>
                  <div className="text-gray-600 dark:text-gray-300 flex-grow font-hemi">
                    <p className="h5 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">Mints #1701-2200</p>
                    <p className="text-center">Fourth 1 ETH draw</p>
                  </div>
                </div>
              </div>

              <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                  <div className="h-full w-6 flex items-center justify-center">
                    <div className="h-full w-1 bg-white dark:bg-gray-800 pointer-events-none"></div>
                  </div>
                  <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-white dark:bg-gray-800 shadow text-center">
                    <i className="fas fa-check-circle text-white"></i>
                  </div>
                </div>
                <div className="bg-white dark:bg-gray-800 col-start-4 col-end-12 p-4 my-4 mr-auto shadow-md w-full">
                  <h3 className="h4 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">
                    25 %
                  </h3>
                  <div className="text-gray-600 dark:text-gray-300 flex-grow font-hemi">
                    <p className="h5 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">Mints #2201-2700</p>
                    <p className="text-center">Upon sell-out of the Pre-sale all holders will be entered into a draw to win any NFT (value up to 3 ETH) of their choice!</p>
                  </div>
                </div>
              </div>

              <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                  <div className="h-full w-6 flex items-center justify-center">
                    <div className="h-full w-1 bg-white dark:bg-gray-800 pointer-events-none"></div>
                  </div>
                  <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-white dark:bg-gray-800 shadow text-center">
                    <i className="fas fa-check-circle text-white"></i>
                  </div>
                </div>
                <div className="col-start-4 col-end-12 p-4 my-4 mr-auto shadow-md w-full text-center">
                  <h3 className="font-hemi text-teal-800 dark:text-teal-500">
                  ------------- PRE-SALE ends -----------
                  </h3>
                </div>
              </div>

              <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                  <div className="h-full w-6 flex items-center justify-center">
                    <div className="h-full w-1 bg-white dark:bg-gray-800 pointer-events-none"></div>
                  </div>
                  <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-white dark:bg-gray-800 shadow text-center">
                    <i className="fas fa-check-circle text-white"></i>
                  </div>
                </div>
                <div className="bg-white dark:bg-gray-800 col-start-4 col-end-12 p-4 my-4 mr-auto shadow-md w-full">
                  <h3 className="h4 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">
                    50 %
                  </h3>
                  <div className="text-gray-600 dark:text-gray-300 flex-grow font-hemi">
                    <p className="h5 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center"></p>
                    <p className="text-center">1 ETH draw for all holders</p>
                  </div>
                </div>
              </div>

              <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                  <div className="h-full w-6 flex items-center justify-center">
                    <div className="h-full w-1 bg-white dark:bg-gray-800 pointer-events-none"></div>
                  </div>
                  <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-white dark:bg-gray-800 shadow text-center">
                    <i className="fas fa-check-circle text-white"></i>
                  </div>
                </div>
                <div className="bg-white dark:bg-gray-800 col-start-4 col-end-12 p-4 my-4 mr-auto shadow-md w-full">
                  <h3 className="h4 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">
                    75 %
                  </h3>
                  <div className="text-gray-600 dark:text-gray-300 flex-grow font-hemi">
                    <p className="h5 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center"></p>
                    <p className="text-center">2 ETH draw for all holders</p>
                  </div>
                </div>
              </div>

              <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                  <div className="h-full w-6 flex items-center justify-center">
                    <div className="h-full w-1 bg-white dark:bg-gray-800 pointer-events-none"></div>
                  </div>
                  <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-white dark:bg-gray-800 shadow text-center">
                    <i className="fas fa-check-circle text-white"></i>
                  </div>
                </div>
                <div className="bg-white dark:bg-gray-800 col-start-4 col-end-12 p-4 my-4 mr-auto shadow-md w-full">
                  <h3 className="h4 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">
                    100 %
                  </h3>
                  <div className="text-gray-600 dark:text-gray-300 flex-grow font-hemi">
                    <p className="h5 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">SOLD OUT</p>
                    <p className="text-center">2 ETH + two 1/1 NFTs of the concept art for The Hustle Club. Your choice of the OG 5!!</p>
                  </div>
                </div>
              </div>

              <div className="flex md:contents">
                <div className="col-start-2 col-end-4 mr-10 md:mx-auto relative">
                  <div className="h-1/2 w-6 flex items-center justify-center">
                    <div className="h-full w-1 bg-white dark:bg-gray-800 pointer-events-none"></div>
                  </div>
                  <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-white dark:bg-gray-800 shadow text-center">
                    <i className="fas fa-check-circle text-white"></i>
                  </div>
                </div>
                <div className="bg-white dark:bg-gray-800 col-start-4 col-end-12 p-4 my-4 mr-auto shadow-md w-full">
                  <h3 className="h4 mb-1 font-hemi text-teal-800 dark:text-teal-500 text-center">
                    POST SALE
                  </h3>
                  <div className="text-gray-600 dark:text-gray-300 flex-grow font-hemi">
                    <p className="text-center"><b className="h5 text-teal-800 dark:text-teal-500">5% of the total sales and 50% of secondary sales</b> will be deposited into a multi-sig community wallet for future development of the community. This will include merch - we are thinking Shadowy Super Coder Hoodies, Keep-it-shill necklaces, THC hats… let see what the community wants & what partnership opportunities pop-up… we gotta hustle after all :) </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>


      </div>
    </section>
  );
}

export default Timeline;