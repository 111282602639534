import React, { useState } from "react";
import Modal from "../../utils/Modal";

function Hero() {

    return (
        <section id="home">
            <div className="pb-10">
                <img src={require('../../images/NFTClubHero.webp').default} />
            </div>
        </section>
    );
}

export default Hero;
