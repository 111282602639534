import React, { useEffect } from "react";
// Import Swiper
import Swiper, { Autoplay, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import Image1 from "../../images/VickyVC.png";
import Image2 from "../../images/DamienDev.png";
import Image3 from "../../images/Felicia.png";
import Image4 from "../../images/LarryLarp.png";
import Image5 from "../../images/SammyShiller.png";

Swiper.use([Autoplay, Navigation]);

function Intro(props) {
    const { selectedAddress } =  props;

    useEffect(() => {
        // eslint-disable-next-line no-unused-vars
        const carousel = new Swiper(".carousel", {
            slidesPerView: "auto",
            grabCursor: true,
            loop: true,
            centeredSlides: true,
            initialSlide: 1,
            spaceBetween: 24,
            // autoplay: {
            //   delay: 7000,
            // },
            navigation: {
                nextEl: ".carousel-next",
                prevEl: ".carousel-prev",
            },
        });
    }, []);

    return (
        <section className=" border-transparent dark:border-gray-800" id="intro">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                {/* CTA box */}
                <div className="dark relative bg-gray-800 py-10 px-8 md:py-16 md:px-12">
                    {/* Background illustration */}
                    <div className="absolute inset-0 left-auto  pointer-events-none" aria-hidden="true">
                        <svg
                            className="h-full"
                            width="400"
                            height="232"
                            viewBox="0 0 400 232"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <defs>
                                <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="39.386%" id="box-gr-a">
                                    <stop stopColor="#667EEA" offset="0%" />
                                    <stop stopColor="#667EEA" stopOpacity="0" offset="100%" />
                                </radialGradient>
                                <radialGradient cx="50%" cy="50%" fx="50%" fy="50%" r="39.386%" id="box-gr-b">
                                    <stop stopColor="#3ABAB4" offset="0%" />
                                    <stop stopColor="#3ABAB4" stopOpacity="0" offset="100%" />
                                </radialGradient>
                            </defs>
                            <g transform="translate(-85 -369)" fill="none" fillRule="evenodd">
                                <circle fillOpacity=".16" fill="url(#box-gr-a)" cx="413" cy="688" r="240" />
                                <circle fillOpacity=".24" fill="url(#box-gr-b)" cx="400" cy="400" r="400" />
                            </g>
                        </svg>
                    </div>

                    <div className="max-w-6xl mx-auto px-4 sm:px-6">
                        {/* Intro */}
                        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                            <p className="font-hemi text-xl text-gray-600 dark:text-gray-200">
                                The year is 2420, and guess what... WAGMI came true. The Hustle Club is a place where
                                all the crypto OGs go to hang out away from the normies now it’s all gone mainstream.
                                Everyone is here, some are... more welcome than others shall we say. But The Hustle Club
                                is where everybody knows your name, or anon name, or pseudo-anon name- anyway. Come join
                                the club fren, see the shillers and sharks, the whales and the minnows, everyone hustled
                                to get here, we were early, we took our chances and made the future ours.
                            </p>
                        </div>

                        {/* Action Call */}
                        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                            <h1 className="h2 font-hemi mb-4">STEP INSIDE THE HUSTLE CLUB</h1>
                        </div>

                        {/* Mint Button */}
                        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                            <button
                                className="btn font-hemi text-2xl text-white px-20 bg-gray-600 hover:bg-gray-600 ml-3"
                                href="#"
                                target="_blank"
                                rel="noreferrer"
                            >
                                MINT
                            </button>

                            {selectedAddress &&
                            <>
                                <h5 class="font-hemi mt-2">{selectedAddress.substring(0, 7)}...{selectedAddress.substring(selectedAddress.length -  8)}</h5>
                            </>
                            }
                        </div>

                        {/* Launch Date */}
                        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
                            <p className="font-hemi text-xl text-gray-600 dark:text-gray-200">
                                WHITELIST PRE-SALE:
                            </p>
                            <p className="font-hemi text-xl text-gray-600 dark:text-gray-200">
                                6PM UTC, 16<sup>th</sup> NOVEMBER 2021
                            </p>
                            <br />
                            <p className="font-hemi text-xl text-gray-600 dark:text-gray-200">
                                PUBLIC SALE:
                            </p>
                            <p className="font-hemi text-xl text-gray-600 dark:text-gray-200">
                                6PM UTC, 17<sup>th</sup> NOVEMBER 2021 
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="py-12 md:py-20">
                <div className="max-w-6xl mx-auto px-4 sm:px-6"></div>

                {/* Carousel built with Swiper.js [https://swiperjs.com/] */}
                {/* * Custom styles in src/css/additional-styles/theme.scss */}
                <div className="carousel swiper-container">
                    <div className="swiper-wrapper">
                        {/* Carousel items */}
                        <div className="swiper-slide max-w-lg">
                            <img
                                className="transition-opacity duration-300"
                                src={Image1}
                                width="540"
                                height="460"
                                alt="Carousel item 01"
                            />
                            <div className="absolute inset-0 flex flex-col transition-opacity duration-300 translate-z-0">
                                <div className="flex flex-grow"></div>
                                <div className="absolute bottom-0 right-0 p-6">
                                    <a
                                        className="text-lg font-medium text-center text-white py-2 px-3 rounded-full bg-gray-900 bg-opacity-50 hover:bg-opacity-100 transition duration-150 ease-in-out"
                                        href="#0"
                                    >
                                        Vicky VC
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide max-w-lg">
                            <img
                                className="transition-opacity duration-300"
                                src={Image2}
                                width="540"
                                height="460"
                                alt="Carousel item 02"
                            />
                            <div className="absolute inset-0 flex flex-col transition-opacity duration-300 translate-z-0">
                                <div className="flex flex-grow"></div>
                                <div className="absolute bottom-0 right-0 p-6">
                                    <a
                                        className="text-lg font-medium text-center text-white py-2 px-3 rounded-full bg-gray-900 bg-opacity-50 hover:bg-opacity-100 transition duration-150 ease-in-out"
                                        href="#0"
                                    >
                                        Damien Dev
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide max-w-lg">
                            <img
                                className="transition-opacity duration-300"
                                src={Image3}
                                width="540"
                                height="460"
                                alt="Carousel item 03"
                            />
                            <div className="absolute inset-0 flex flex-col transition-opacity duration-300 translate-z-0">
                                <div className="flex flex-grow"></div>
                                <div className="absolute bottom-0 right-0 p-6">
                                    <a
                                        className="text-lg font-medium text-center text-white py-2 px-3 rounded-full bg-gray-900 bg-opacity-50 hover:bg-opacity-100 transition duration-150 ease-in-out"
                                        href="#0"
                                    >
                                        Felicia Flipper
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide max-w-lg">
                            <img
                                className="transition-opacity duration-300"
                                src={Image4}
                                width="540"
                                height="460"
                                alt="Carousel item 04"
                            />
                            <div className="absolute inset-0 flex flex-col transition-opacity duration-300 translate-z-0">
                                <div className="flex flex-grow"></div>
                                <div className="absolute bottom-0 right-0 p-6">
                                    <a
                                        className="text-lg font-medium text-center text-white py-2 px-3 rounded-full bg-gray-900 bg-opacity-50 hover:bg-opacity-100 transition duration-150 ease-in-out"
                                        href="#0"
                                    >
                                        Larry Larp
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="swiper-slide max-w-lg">
                            <img
                                className="transition-opacity duration-300"
                                src={Image5}
                                width="540"
                                height="460"
                                alt="Carousel item 05"
                            />
                            <div className="absolute inset-0 flex flex-col transition-opacity duration-300 translate-z-0">
                                <div className="flex flex-grow"></div>
                                <div className="absolute bottom-0 right-0 p-6">
                                    <a
                                        className="text-lg font-medium text-center text-white py-2 px-3 rounded-full bg-gray-900 bg-opacity-50 hover:bg-opacity-100 transition duration-150 ease-in-out"
                                        href="#0"
                                    >
                                        Sammy Shiller
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Arrows */}
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                    <div className="flex items-center justify-between mt-12 md:mt-16">
                        <button className="carousel-prev relative z-20 w-12 h-12 p-1 box-content flex items-center justify-center group bg-teal-500 hover:bg-teal-400 dark:bg-gray-800 dark:hover:bg-teal-500 dark:hover:bg-opacity-25 shadow-xl transition duration-150 ease-in-out">
                            <span className="sr-only">Previous</span>
                            <svg
                                className="w-4 h-4 fill-current text-white dark:text-gray-400 group-hover:text-white dark:group-hover:text-teal-500 transition duration-150 ease-in-out"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M6.7 14.7l1.4-1.4L3.8 9H16V7H3.8l4.3-4.3-1.4-1.4L0 8z" />
                            </svg>
                        </button>
                        <button className="carousel-next relative z-20 w-12 h-12 p-1 box-content flex items-center justify-center group bg-teal-500 hover:bg-teal-400 dark:bg-gray-800 dark:hover:bg-teal-500 dark:hover:bg-opacity-25 shadow-xl transition duration-150 ease-in-out">
                            <span className="sr-only">Next</span>
                            <svg
                                className="w-4 h-4 fill-current text-white dark:text-gray-400 group-hover:text-white dark:group-hover:text-teal-500 transition duration-150 ease-in-out"
                                viewBox="0 0 16 16"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M9.3 14.7l-1.4-1.4L12.2 9H0V7h12.2L7.9 2.7l1.4-1.4L16 8z" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Intro;
