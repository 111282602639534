import React from 'react';

function About() {

  return (
    <section id="about">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        <div className="py-12 md:py-20 border-t border-gray-200 dark:border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <h1 className="h2 font-hemi mb-4 dark:text-gray-200">About</h1>
          </div>

          {/* Section footer */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <p className="font-hemi text-xl text-gray-600 dark:text-gray-200">
              The Hustle Club came from an idea our founder Johnny Hustle had last year while sitting at his desk doodling, he kept sketching and Sammy Shiller was born (guess which Twitter account he was thinking of 👀 )
            </p>
          </div>

          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <p className="font-hemi text-xl text-gray-600 dark:text-gray-200">
              It is a self-referential nod to all of us in the crypto space, particularly on Twitter. After all we have to be able to poke fun at ourselves right Anon? Ser 10,000 members will be generated from over 200 traits.
            </p>
          </div>
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <p className="font-hemi text-xl text-gray-600 dark:text-gray-200">
              There are 10 custom 1/1s that are hand-drawn in there for you to mint. These include our OG 5: Sammy Shiller, Damien Dev, Vicky VC, Felicia Flipper and Larry Larp. The lucky minters may pick up something extra :)
            </p>
          </div>
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <p className="font-hemi text-xl text-gray-600 dark:text-gray-200">
              For the rest, maybe you end up with that reasonably sized house or a lambo? Or maybe you’re in your moms basement. We tried to weave the culture into as many of the traits as possible. Either way you’re a member fren. 
            </p>
          </div>
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
            <p className="font-hemi text-xl text-gray-600 dark:text-gray-200">
              THCs team have all been involved in the space for many years, we wanted to make something fun for the community, remember WAGMI. 
            </p>
          </div>

        </div>        

      </div>
    </section>
  );
}

export default About;
