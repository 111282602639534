import React, { useEffect, useState } from "react";
import { Switch, Route, useLocation } from "react-router-dom";

import "./css/style.scss";

import AOS from "aos";
import { focusHandling } from "cruip-js-toolkit";

import Home from "./pages/Home";
import Testimonials from "./pages/Testimonials";
import Blog from "./pages/Blog";
import BlogPost from "./pages/BlogPost";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Help from "./pages/Help";
import PageNotFound from "./pages/PageNotFound";

function App() {
    const location = useLocation();

    const chainId = '0x1'
    // const contractAddress = '0x4D7fc3eEB59A75207702894282e132470130a0Ed'
    const [selectedAddress, setSelectedAddress] = useState(null)

    useEffect(() => {
        const load = async () => {
            await window.ethereum.request({ method: 'eth_requestAccounts' })
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId }]
            })
            await window.ethereum.request({ method: 'eth_requestAccounts' })

            setSelectedAddress(window.ethereum.selectedAddress)
        }

        load()
    }, [])

    useEffect(() => {
        AOS.init({
            once: true,
            disable: "phone",
            duration: 750,
            easing: "ease-out-quart",
        });
    });

    useEffect(() => {
        document.querySelector("html").style.scrollBehavior = "auto";
        window.scroll({ top: 0 });
        document.querySelector("html").style.scrollBehavior = "";
        focusHandling("outline");
    }, [location.pathname]); // triggered on route change

    return (
        <>
            <Switch>
                <Route exact path="/">
                    <Home selectedAddress={selectedAddress} />
                </Route>
                <Route path="*">
                    <PageNotFound />
                </Route>
            </Switch>
        </>
    );
}

export default App;
