import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Dropdown from "../../utils/Dropdown";
import Transition from "../../utils/Transition";

function Header() {
    const [mobileNavOpen, setMobileNavOpen] = useState(false);

    const trigger = useRef(null);
    const mobileNav = useRef(null);

    // close the mobile menu on click outside
    useEffect(() => {
        const clickHandler = ({ target }) => {
            if (!mobileNav.current || !trigger.current) return;
            console.log(mobileNav.current.contains(target));
            if (!mobileNavOpen || mobileNav.current.contains(target) || trigger.current.contains(target)) return;
            setMobileNavOpen(false);
        };
        document.addEventListener("click", clickHandler);
        return () => document.removeEventListener("click", clickHandler);
    });

    // close the mobile menu if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }) => {
            if (!mobileNavOpen || keyCode !== 27) return;
            setMobileNavOpen(false);
        };
        document.addEventListener("keydown", keyHandler);
        return () => document.removeEventListener("keydown", keyHandler);
    });

    // Handle light modes
    const [darkMode, setDarkMode] = useState(() => {
        const dark = localStorage.getItem("dark-mode");
        if (dark === null) {
            return true;
        } else {
            return dark === "true";
        }
    });

    useEffect(() => {
        localStorage.setItem("dark-mode", darkMode);
        if (darkMode) {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }, [darkMode]);

    return (
        <header className="absolute w-full z-30">
            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                <div className="flex items-center justify-between h-20">
                    {/* Site branding */}
                    <div className="flex-shrink-0 mr-5">
                        {/* Logo */}
                        {/* <Link to="/" className="block" aria-label="Cruip">
                            <svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <linearGradient x1="26%" y1="100%" x2="100%" y2="100%" id="logo_a">
                                        <stop stopColor="#3ABAB4" offset="0%" />
                                        <stop stopColor="#7F9CF5" offset="100%" />
                                    </linearGradient>
                                    <linearGradient x1="26%" y1="100%" x2="100%" y2="100%" id="logo_b">
                                        <stop stopColor="#3ABAB4" offset="0%" />
                                        <stop stopColor="#3ABAB4" stopOpacity="0" offset="100%" />
                                    </linearGradient>
                                </defs>
                                <path
                                    d="M32 16h-8a8 8 0 10-16 0H0C0 7.163 7.163 0 16 0s16 7.163 16 16z"
                                    fill="url(#logo_a)"
                                />
                                <path
                                    d="M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16h8a8 8 0 1016 0h8z"
                                    fill="url(#logo_b)"
                                />
                            </svg>
                        </Link> */}
                    </div>

                    {/* Desktop navigation */}
                    <nav className="hidden md:flex md:flex-grow pt-5">
                        {/* Desktop menu links */}
                        <ul className="flex flex-grow flex-wrap justify-between font-medium">
                            <li>
                                <a
                                    href="#home"
                                    className="font-hemi text-xl text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-indigo-600 py-2 flex items-center transition duration-150 ease-in-out"
                                >
                                    Home
                                </a>
                            </li>
                            {/* <li>
                                <a
                                    href="#intro"
                                    className="font-hemi text-xl text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-indigo-600 py-2 flex items-center transition duration-150 ease-in-out"
                                >
                                    Intro
                                </a>
                            </li> */}
                            <li>
                                <a
                                    href="#about"
                                    className="font-hemi text-xl text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-indigo-600 py-2 flex items-center transition duration-150 ease-in-out"
                                >
                                    About
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#roadmap"
                                    className="font-hemi text-xl text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-indigo-600 py-2 flex items-center transition duration-1500 ease-in-out"
                                >
                                    Roadmap
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#faq"
                                    className="font-hemi text-xl text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-indigo-600 py-2 flex items-center transition duration-1500 ease-in-out"
                                >
                                    FAQ
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#team"
                                    className="font-hemi text-xl text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-indigo-600 py-2 flex items-center transition duration-150 ease-in-out"
                                >
                                    Team
                                </a>
                            </li>
                            <li>
                                {/* Twitter */}
                                <a href="https://twitter.com/HustleClubNFT" className="pl-10 py-2 flex items-center transition dark:hover:text-blue-400 duration-150 ease-in-out">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                                        <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                {/* Discord */}
                                <a href="https://discord.gg/68KTB9X94Q" className="py-2 flex items-center transition dark:hover:text-indigo-600 duration-150 ease-in-out">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-discord" viewBox="0 0 16 16">
                                        <path d="M6.552 6.712c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888.008-.488-.36-.888-.816-.888zm2.92 0c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888s-.36-.888-.816-.888z"/>
                                        <path d="M13.36 0H2.64C1.736 0 1 .736 1 1.648v10.816c0 .912.736 1.648 1.64 1.648h9.072l-.424-1.48 1.024.952.968.896L15 16V1.648C15 .736 14.264 0 13.36 0zm-3.088 10.448s-.288-.344-.528-.648c1.048-.296 1.448-.952 1.448-.952-.328.216-.64.368-.92.472-.4.168-.784.28-1.16.344a5.604 5.604 0 0 1-2.072-.008 6.716 6.716 0 0 1-1.176-.344 4.688 4.688 0 0 1-.584-.272c-.024-.016-.048-.024-.072-.04-.016-.008-.024-.016-.032-.024-.144-.08-.224-.136-.224-.136s.384.64 1.4.944c-.24.304-.536.664-.536.664-1.768-.056-2.44-1.216-2.44-1.216 0-2.576 1.152-4.664 1.152-4.664 1.152-.864 2.248-.84 2.248-.84l.08.096c-1.44.416-2.104 1.048-2.104 1.048s.176-.096.472-.232c.856-.376 1.536-.48 1.816-.504.048-.008.088-.016.136-.016a6.521 6.521 0 0 1 4.024.752s-.632-.6-1.992-1.016l.112-.128s1.096-.024 2.248.84c0 0 1.152 2.088 1.152 4.664 0 0-.68 1.16-2.448 1.216z"/>
                                    </svg>
                                </a>
                            </li>
                            <li>
                                {/* Opensea */}
                                <a href="#" className="py-2 flex items-center transition dark:hover:text-blue-500 duration-150 ease-in-out">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 90 90">
                                        <path id="Imported Path"
                                                d="M 45.00,0.00
                                                C 20.15,0.00 0.00,20.15 0.00,45.00
                                                    0.00,69.85 20.15,90.00 45.00,90.00
                                                    69.85,90.00 90.00,69.85 90.00,45.00
                                                    90.00,20.15 69.86,0.00 45.00,0.00 Z
                                                M 22.20,46.51
                                                C 22.20,46.51 22.39,46.21 22.39,46.21
                                                    22.39,46.21 34.10,27.89 34.10,27.89
                                                    34.27,27.63 34.68,27.66 34.80,27.94
                                                    36.76,32.33 38.45,37.78 37.66,41.18
                                                    37.32,42.57 36.40,44.46 35.35,46.21
                                                    35.22,46.46 35.07,46.71 34.91,46.95
                                                    34.84,47.06 34.71,47.12 34.58,47.12
                                                    34.58,47.12 22.54,47.12 22.54,47.12
                                                    22.22,47.12 22.03,46.77 22.20,46.51 Z
                                                M 74.38,52.81
                                                C 74.38,52.98 74.28,53.13 74.13,53.19
                                                    73.22,53.58 70.12,55.01 68.83,56.80
                                                    65.54,61.38 63.03,67.93 57.40,67.93
                                                    57.40,67.93 33.95,67.93 33.95,67.93
                                                    25.63,67.93 18.90,61.17 18.90,52.83
                                                    18.90,52.83 18.90,52.56 18.90,52.56
                                                    18.90,52.34 19.08,52.16 19.30,52.16
                                                    19.30,52.16 32.37,52.16 32.37,52.16
                                                    32.63,52.16 32.82,52.40 32.80,52.66
                                                    32.71,53.51 32.87,54.38 33.27,55.17
                                                    34.05,56.75 35.66,57.73 37.40,57.73
                                                    37.40,57.73 43.87,57.73 43.87,57.73
                                                    43.87,57.73 43.87,52.68 43.87,52.68
                                                    43.87,52.68 37.47,52.68 37.47,52.68
                                                    37.14,52.68 36.95,52.30 37.13,52.03
                                                    37.21,51.92 37.28,51.81 37.37,51.69
                                                    37.97,50.82 38.84,49.49 39.70,47.97
                                                    40.28,46.94 40.85,45.85 41.31,44.75
                                                    41.40,44.55 41.47,44.34 41.55,44.15
                                                    41.68,43.79 41.80,43.46 41.90,43.14
                                                    41.98,42.86 42.07,42.57 42.14,42.30
                                                    42.35,41.36 42.44,40.37 42.44,39.35
                                                    42.44,38.94 42.43,38.52 42.39,38.12
                                                    42.37,37.68 42.32,37.24 42.26,36.80
                                                    42.23,36.41 42.16,36.03 42.08,35.63
                                                    41.98,35.05 41.86,34.46 41.72,33.88
                                                    41.72,33.88 41.66,33.65 41.66,33.65
                                                    41.55,33.25 41.45,32.87 41.33,32.46
                                                    40.96,31.20 40.55,29.97 40.10,28.82
                                                    39.93,28.36 39.75,27.92 39.56,27.49
                                                    39.29,26.82 39.02,26.22 38.76,25.65
                                                    38.63,25.39 38.52,25.16 38.41,24.91
                                                    38.29,24.64 38.16,24.37 38.03,24.11
                                                    37.94,23.91 37.83,23.72 37.76,23.54
                                                    37.76,23.54 36.96,22.09 36.96,22.09
                                                    36.85,21.89 37.04,21.64 37.25,21.71
                                                    37.25,21.71 42.20,23.05 42.20,23.05
                                                    42.20,23.05 42.22,23.05 42.22,23.05
                                                    42.23,23.05 42.23,23.05 42.24,23.05
                                                    42.24,23.05 42.88,23.24 42.88,23.24
                                                    42.88,23.24 43.61,23.44 43.61,23.44
                                                    43.61,23.44 43.87,23.51 43.87,23.51
                                                    43.87,23.51 43.87,20.57 43.87,20.57
                                                    43.87,19.15 45.00,18.00 46.41,18.00
                                                    47.12,18.00 47.75,18.29 48.20,18.76
                                                    48.66,19.22 48.95,19.86 48.95,20.57
                                                    48.95,20.57 48.95,24.94 48.95,24.94
                                                    48.95,24.94 49.48,25.08 49.48,25.08
                                                    49.52,25.10 49.56,25.12 49.60,25.15
                                                    49.73,25.24 49.91,25.38 50.15,25.56
                                                    50.34,25.70 50.54,25.88 50.77,26.07
                                                    51.25,26.46 51.82,26.95 52.44,27.52
                                                    52.61,27.67 52.77,27.81 52.92,27.96
                                                    53.72,28.71 54.62,29.58 55.48,30.55
                                                    55.73,30.83 55.96,31.10 56.21,31.40
                                                    56.44,31.70 56.70,31.99 56.92,32.27
                                                    57.21,32.66 57.52,33.07 57.80,33.49
                                                    57.92,33.69 58.08,33.89 58.19,34.09
                                                    58.55,34.62 58.86,35.17 59.16,35.72
                                                    59.28,35.97 59.41,36.25 59.52,36.52
                                                    59.85,37.26 60.11,38.01 60.27,38.76
                                                    60.33,38.93 60.36,39.10 60.38,39.26
                                                    60.38,39.26 60.38,39.29 60.38,39.29
                                                    60.44,39.51 60.45,39.74 60.47,39.99
                                                    60.54,40.75 60.51,41.53 60.34,42.30
                                                    60.27,42.62 60.18,42.93 60.08,43.26
                                                    59.96,43.58 59.85,43.90 59.71,44.22
                                                    59.43,44.86 59.10,45.50 58.72,46.10
                                                    58.59,46.32 58.44,46.56 58.29,46.78
                                                    58.13,47.02 57.96,47.24 57.82,47.46
                                                    57.61,47.74 57.39,48.02 57.17,48.29
                                                    56.97,48.55 56.77,48.83 56.55,49.07
                                                    56.24,49.44 55.94,49.78 55.63,50.11
                                                    55.45,50.33 55.25,50.55 55.04,50.75
                                                    54.85,50.98 54.64,51.17 54.46,51.35
                                                    54.14,51.67 53.89,51.90 53.68,52.11
                                                    53.68,52.11 53.16,52.57 53.16,52.57
                                                    53.09,52.64 52.99,52.68 52.89,52.68
                                                    52.89,52.68 48.95,52.68 48.95,52.68
                                                    48.95,52.68 48.95,57.73 48.95,57.73
                                                    48.95,57.73 53.91,57.73 53.91,57.73
                                                    55.02,57.73 56.07,57.34 56.93,56.61
                                                    57.21,56.36 58.48,55.26 59.98,53.60
                                                    60.04,53.54 60.10,53.51 60.17,53.49
                                                    60.17,53.49 73.86,49.53 73.86,49.53
                                                    74.12,49.45 74.38,49.64 74.38,49.91
                                                    74.38,49.91 74.38,52.81 74.38,52.81
                                                    74.38,52.81 74.38,52.81 74.38,52.81 Z" />
                                        </svg>
                                </a>
                            </li>
                            <li>
                                {/* Mint Button */}
                                <div className="pl-5 flex items-center">
                                    <button className="btn font-hemi text-white bg-gray-600 hover:bg-gray-600 ml-3" href="#" target="_blank" rel="noreferrer">MINT</button>
                                </div>
                            </li>
                        </ul>



                        {/* Desktop CTA on the right */}
                        {/* <ul className="flex justify-end flex-wrap items-center">
                            <li>
                                <Link to="/contact" className="btn-sm text-white bg-teal-500 hover:bg-teal-400 ml-6">
                                    Request code
                                </Link>
                            </li>
                        </ul> */}
                    </nav>

                    {/* Mobile menu */}
                    <div className="inline-flex md:hidden">

                        {/* Hamburger button */}
                        <button
                            ref={trigger}
                            className={`hamburger ${mobileNavOpen && "active"}`}
                            aria-controls="mobile-nav"
                            aria-expanded={mobileNavOpen}
                            onClick={() => setMobileNavOpen(!mobileNavOpen)}
                        >
                            <span className="sr-only">Menu</span>
                            <svg
                                className="w-6 h-6 fill-current text-gray-900 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 transition duration-150 ease-in-out"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <rect y="4" width="24" height="2" rx="1" />
                                <rect y="11" width="24" height="2" rx="1" />
                                <rect y="18" width="24" height="2" rx="1" />
                            </svg>
                        </button>

                        {/*Mobile navigation */}
                        <Transition
                            show={mobileNavOpen}
                            tag="ul"
                            className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg"
                            enter="transition ease-out duration-200 transform"
                            enterStart="opacity-0 -translate-x-full"
                            enterEnd="opacity-100 translate-x-0"
                            leave="transition ease-out duration-200"
                            leaveStart="opacity-100"
                            leaveEnd="opacity-0"
                        >
                            <nav
                                id="mobile-nav"
                                ref={mobileNav}
                                className="fixed top-0 h-screen z-20 left-0 w-full max-w-sm -ml-16 overflow-scroll bg-white dark:bg-gray-900 shadow-lg no-scrollbar"
                            >
                                <div className="py-6 pr-4 pl-20">
                                    {/* Logo */}
                                    {/* <svg className="w-8 h-8" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <defs>
                                            <linearGradient x1="26%" y1="100%" x2="100%" y2="100%" id="menulogo_a">
                                                <stop stopColor="#3ABAB4" offset="0%" />
                                                <stop stopColor="#7F9CF5" offset="100%" />
                                            </linearGradient>
                                            <linearGradient x1="26%" y1="100%" x2="100%" y2="100%" id="menulogo_b">
                                                <stop stopColor="#3ABAB4" offset="0%" />
                                                <stop stopColor="#3ABAB4" stopOpacity="0" offset="100%" />
                                            </linearGradient>
                                        </defs>
                                        <path
                                            d="M32 16h-8a8 8 0 10-16 0H0C0 7.163 7.163 0 16 0s16 7.163 16 16z"
                                            fill="url(#menulogo_a)"
                                        />
                                        <path
                                            d="M32 16c0 8.837-7.163 16-16 16S0 24.837 0 16h8a8 8 0 1016 0h8z"
                                            fill="url(#menulogo_b)"
                                        />
                                    </svg> */}
                                    {/* Links */}
                                    <ul>
                                        <li>
                                            <a
                                                href="#home"
                                                className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                                            >
                                                Home
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#carousel"
                                                className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                                            >
                                                About
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#roadmap"
                                                className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                                            >
                                                Roadmap
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#faq"
                                                className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                                            >
                                                FAQ
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="#team"
                                                className="flex text-gray-600 hover:text-gray-900 dark:text-gray-300 dark:hover:text-gray-100 py-2"
                                            >
                                                Team
                                            </a>
                                        </li>
                                    </ul>
                                    {/* Twitter */}
                                    <br />
                                    <a href="twitter.com/hustleclub">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
                                        </svg>
                                    </a>
                                    {/* Discord */}
                                    <br />
                                    <a href="https://discord.gg/68KTB9X94Q">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-discord" viewBox="0 0 16 16">
                                            <path d="M6.552 6.712c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888.008-.488-.36-.888-.816-.888zm2.92 0c-.456 0-.816.4-.816.888s.368.888.816.888c.456 0 .816-.4.816-.888s-.36-.888-.816-.888z"/>
                                            <path d="M13.36 0H2.64C1.736 0 1 .736 1 1.648v10.816c0 .912.736 1.648 1.64 1.648h9.072l-.424-1.48 1.024.952.968.896L15 16V1.648C15 .736 14.264 0 13.36 0zm-3.088 10.448s-.288-.344-.528-.648c1.048-.296 1.448-.952 1.448-.952-.328.216-.64.368-.92.472-.4.168-.784.28-1.16.344a5.604 5.604 0 0 1-2.072-.008 6.716 6.716 0 0 1-1.176-.344 4.688 4.688 0 0 1-.584-.272c-.024-.016-.048-.024-.072-.04-.016-.008-.024-.016-.032-.024-.144-.08-.224-.136-.224-.136s.384.64 1.4.944c-.24.304-.536.664-.536.664-1.768-.056-2.44-1.216-2.44-1.216 0-2.576 1.152-4.664 1.152-4.664 1.152-.864 2.248-.84 2.248-.84l.08.096c-1.44.416-2.104 1.048-2.104 1.048s.176-.096.472-.232c.856-.376 1.536-.48 1.816-.504.048-.008.088-.016.136-.016a6.521 6.521 0 0 1 4.024.752s-.632-.6-1.992-1.016l.112-.128s1.096-.024 2.248.84c0 0 1.152 2.088 1.152 4.664 0 0-.68 1.16-2.448 1.216z"/>
                                        </svg>
                                    </a>
                                    {/* Opensea */}
                                    <br />
                                    <a href="#">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi" viewBox="0 0 90 90">
                                            <path id="Imported Path"
                                                    d="M 45.00,0.00
                                                    C 20.15,0.00 0.00,20.15 0.00,45.00
                                                        0.00,69.85 20.15,90.00 45.00,90.00
                                                        69.85,90.00 90.00,69.85 90.00,45.00
                                                        90.00,20.15 69.86,0.00 45.00,0.00 Z
                                                    M 22.20,46.51
                                                    C 22.20,46.51 22.39,46.21 22.39,46.21
                                                        22.39,46.21 34.10,27.89 34.10,27.89
                                                        34.27,27.63 34.68,27.66 34.80,27.94
                                                        36.76,32.33 38.45,37.78 37.66,41.18
                                                        37.32,42.57 36.40,44.46 35.35,46.21
                                                        35.22,46.46 35.07,46.71 34.91,46.95
                                                        34.84,47.06 34.71,47.12 34.58,47.12
                                                        34.58,47.12 22.54,47.12 22.54,47.12
                                                        22.22,47.12 22.03,46.77 22.20,46.51 Z
                                                    M 74.38,52.81
                                                    C 74.38,52.98 74.28,53.13 74.13,53.19
                                                        73.22,53.58 70.12,55.01 68.83,56.80
                                                        65.54,61.38 63.03,67.93 57.40,67.93
                                                        57.40,67.93 33.95,67.93 33.95,67.93
                                                        25.63,67.93 18.90,61.17 18.90,52.83
                                                        18.90,52.83 18.90,52.56 18.90,52.56
                                                        18.90,52.34 19.08,52.16 19.30,52.16
                                                        19.30,52.16 32.37,52.16 32.37,52.16
                                                        32.63,52.16 32.82,52.40 32.80,52.66
                                                        32.71,53.51 32.87,54.38 33.27,55.17
                                                        34.05,56.75 35.66,57.73 37.40,57.73
                                                        37.40,57.73 43.87,57.73 43.87,57.73
                                                        43.87,57.73 43.87,52.68 43.87,52.68
                                                        43.87,52.68 37.47,52.68 37.47,52.68
                                                        37.14,52.68 36.95,52.30 37.13,52.03
                                                        37.21,51.92 37.28,51.81 37.37,51.69
                                                        37.97,50.82 38.84,49.49 39.70,47.97
                                                        40.28,46.94 40.85,45.85 41.31,44.75
                                                        41.40,44.55 41.47,44.34 41.55,44.15
                                                        41.68,43.79 41.80,43.46 41.90,43.14
                                                        41.98,42.86 42.07,42.57 42.14,42.30
                                                        42.35,41.36 42.44,40.37 42.44,39.35
                                                        42.44,38.94 42.43,38.52 42.39,38.12
                                                        42.37,37.68 42.32,37.24 42.26,36.80
                                                        42.23,36.41 42.16,36.03 42.08,35.63
                                                        41.98,35.05 41.86,34.46 41.72,33.88
                                                        41.72,33.88 41.66,33.65 41.66,33.65
                                                        41.55,33.25 41.45,32.87 41.33,32.46
                                                        40.96,31.20 40.55,29.97 40.10,28.82
                                                        39.93,28.36 39.75,27.92 39.56,27.49
                                                        39.29,26.82 39.02,26.22 38.76,25.65
                                                        38.63,25.39 38.52,25.16 38.41,24.91
                                                        38.29,24.64 38.16,24.37 38.03,24.11
                                                        37.94,23.91 37.83,23.72 37.76,23.54
                                                        37.76,23.54 36.96,22.09 36.96,22.09
                                                        36.85,21.89 37.04,21.64 37.25,21.71
                                                        37.25,21.71 42.20,23.05 42.20,23.05
                                                        42.20,23.05 42.22,23.05 42.22,23.05
                                                        42.23,23.05 42.23,23.05 42.24,23.05
                                                        42.24,23.05 42.88,23.24 42.88,23.24
                                                        42.88,23.24 43.61,23.44 43.61,23.44
                                                        43.61,23.44 43.87,23.51 43.87,23.51
                                                        43.87,23.51 43.87,20.57 43.87,20.57
                                                        43.87,19.15 45.00,18.00 46.41,18.00
                                                        47.12,18.00 47.75,18.29 48.20,18.76
                                                        48.66,19.22 48.95,19.86 48.95,20.57
                                                        48.95,20.57 48.95,24.94 48.95,24.94
                                                        48.95,24.94 49.48,25.08 49.48,25.08
                                                        49.52,25.10 49.56,25.12 49.60,25.15
                                                        49.73,25.24 49.91,25.38 50.15,25.56
                                                        50.34,25.70 50.54,25.88 50.77,26.07
                                                        51.25,26.46 51.82,26.95 52.44,27.52
                                                        52.61,27.67 52.77,27.81 52.92,27.96
                                                        53.72,28.71 54.62,29.58 55.48,30.55
                                                        55.73,30.83 55.96,31.10 56.21,31.40
                                                        56.44,31.70 56.70,31.99 56.92,32.27
                                                        57.21,32.66 57.52,33.07 57.80,33.49
                                                        57.92,33.69 58.08,33.89 58.19,34.09
                                                        58.55,34.62 58.86,35.17 59.16,35.72
                                                        59.28,35.97 59.41,36.25 59.52,36.52
                                                        59.85,37.26 60.11,38.01 60.27,38.76
                                                        60.33,38.93 60.36,39.10 60.38,39.26
                                                        60.38,39.26 60.38,39.29 60.38,39.29
                                                        60.44,39.51 60.45,39.74 60.47,39.99
                                                        60.54,40.75 60.51,41.53 60.34,42.30
                                                        60.27,42.62 60.18,42.93 60.08,43.26
                                                        59.96,43.58 59.85,43.90 59.71,44.22
                                                        59.43,44.86 59.10,45.50 58.72,46.10
                                                        58.59,46.32 58.44,46.56 58.29,46.78
                                                        58.13,47.02 57.96,47.24 57.82,47.46
                                                        57.61,47.74 57.39,48.02 57.17,48.29
                                                        56.97,48.55 56.77,48.83 56.55,49.07
                                                        56.24,49.44 55.94,49.78 55.63,50.11
                                                        55.45,50.33 55.25,50.55 55.04,50.75
                                                        54.85,50.98 54.64,51.17 54.46,51.35
                                                        54.14,51.67 53.89,51.90 53.68,52.11
                                                        53.68,52.11 53.16,52.57 53.16,52.57
                                                        53.09,52.64 52.99,52.68 52.89,52.68
                                                        52.89,52.68 48.95,52.68 48.95,52.68
                                                        48.95,52.68 48.95,57.73 48.95,57.73
                                                        48.95,57.73 53.91,57.73 53.91,57.73
                                                        55.02,57.73 56.07,57.34 56.93,56.61
                                                        57.21,56.36 58.48,55.26 59.98,53.60
                                                        60.04,53.54 60.10,53.51 60.17,53.49
                                                        60.17,53.49 73.86,49.53 73.86,49.53
                                                        74.12,49.45 74.38,49.64 74.38,49.91
                                                        74.38,49.91 74.38,52.81 74.38,52.81
                                                        74.38,52.81 74.38,52.81 74.38,52.81 Z" />
                                        
                                        </svg>
                                    </a>
                                </div>
                            </nav>
                        </Transition>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
