import React from 'react';
import PlaceholderImage from '../../images/LarryLarp.webp';

function Team() {
  return (
    <section id="team">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-12 md:py-20 border-t border-gray-200 dark:border-gray-800">

          {/* Section header */}
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h2 className="h2 font-hemi mb-4">Team</h2>
          </div>

          {/* teams container */}
          <div className="grid gap-12 sm:grid-cols-3 sm:gap-x-6 md:gap-y-8 items-start">

          {/* Team Member */}
          <team className="flex flex-col h-full">
            <header>
              {/* <a href="#" className="block mb-4">
                <figure className="relative h-0 pb-1/1">
                  <img className="absolute inset-0 w-full h-full object-cover" src={PlaceholderImage} alt="Team Member" />
                </figure>
              </a> */}
              <a href="#" className="hover:underline">
                <h3 className="h4 font-hemi text-center mb-2">
                  JOHNNY HUSTLE
                </h3>
              </a>
            </header>
            <p className="font-hemi text-gray-600 dark:text-gray-400 flex-grow text-center">Founder, Hustler, Hodler. Defi degen since AAVE was ETHlend, NFT collector (some say hoarder...)</p>
          </team>

          {/* Team Member */}
          <team className="flex flex-col h-full">
            <header>
              {/* <a href="#" className="block mb-4">
                <figure className="relative h-0 pb-1/1">
                  <img className="absolute inset-0 w-full h-full object-cover" src={PlaceholderImage} alt="Team Member" />
                </figure>
              </a> */}
              <a href="#" className="hover:underline">
                <h3 className="h4 font-hemi text-center mb-2">
                  BRI LEVI
                </h3>
              </a>
            </header>
            <p className="font-hemi text-gray-600 dark:text-gray-400 flex-grow text-center">An alien being of light, just here for a while to draw some sh!t</p>
          </team>

          {/* Team Member */}
          <team className="flex flex-col h-full">
            <header>
              {/* <a href="#" className="block mb-4">
                <figure className="relative h-0 pb-1/1">
                  <img className="absolute inset-0 w-full h-full object-cover" src={PlaceholderImage} alt="Team Member" />
                </figure>
              </a> */}
              <a href="#" className="hover:underline">
                <h3 className="h4 font-hemi text-center mb-2">
                  WAGMINOMIST
                </h3>
              </a>
            </header>
            <p className="font-hemi text-gray-600 dark:text-gray-400 flex-grow text-center">A disciple of wagminomics and our talented front end dev</p>
          </team>

          {/* Team Member */}
          <team className="flex flex-col h-full">
            <header>
              {/* <a href="#" className="block mb-4">
                <figure className="relative h-0 pb-1/1">
                  <img className="absolute inset-0 w-full h-full object-cover" src={PlaceholderImage} alt="Team Member" />
                </figure>
              </a> */}
              <a href="#" className="hover:underline">
                <h3 className="h4 font-hemi  text-center mb-2">
                  OxSKITTLES
                </h3>
              </a>
            </header>
            <p className="text-gray-600 dark:text-gray-400 flex-grow text-center font-hemi">Shadowy super coder and smart contract engineer</p>
          </team>

          {/* Team Member */}
          <team className="flex flex-col h-full">
            <header>
              {/* <a href="#" className="block mb-4">
                <figure className="relative h-0 pb-1/1">
                  <img className="absolute inset-0 w-full h-full object-cover" src={PlaceholderImage} alt="Team Member" />
                </figure>
              </a> */}
              <a href="#" className="hover:underline">
                <h3 className="h4 font-hemi text-center mb-2">
                  ORKO
                </h3>
              </a>
            </header>
            <p className="font-hemi text-gray-600 dark:text-gray-400 flex-grow text-center">Community Designer, Builder, & Manager navigating the metaverse.</p>
          </team>

          {/* Team Member */}
          <team className="flex flex-col h-full">
            <header>
              {/* <a href="#" className="block mb-4">
                <figure className="relative h-0 pb-1/1">
                  <img className="absolute inset-0 w-full h-full object-cover" src={PlaceholderImage} alt="Team Member" />
                </figure>
              </a> */}
              <a href="#" className="hover:underline">
                <h3 className="h4 font-hemi text-center mb-2">
                  MATTY
                </h3>
              </a>
            </header>
            <p className="font-hemi text-gray-600 dark:text-gray-400 flex-grow text-center">Resident SEAL team leader. When asked for comment Matty just said 'Hooyah!'</p>
          </team>

          </div>


        </div>
      </div>
    </section>

    
  );
}

export default Team;