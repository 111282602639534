import React from 'react';

function FAQ() {

  return (
    <section id="faq">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">

        <div className="py-12 md:py-20 border-t border-gray-200 dark:border-gray-800">

          <div class="bg-lightblue py-20 px-4">
              <div class="mx-auto max-w-6xl flex flex-col md:flex-row">
                  <h2 class="pb-10 mr-8 w-full md:w-1/3 text-3xl leading-9 text-gray-600 dark:text-gray-200 font-hemi text-center">
                      Frequently Asked Questions
                  </h2>
                  <br /><br />
                  <dl class="w-full md:w-2/3">
                      <dt class="mb-4">
                          <h3 class="text-xl text-gray-600 dark:text-gray-200 flex-grow font-hemi">
                              How Much?
                          </h3>
                      </dt>
                      <dd class="mb-16">
                          <p className="text-lg text-gray-600 dark:text-gray-500 flex-grow font-hemi">
                            Mint price: 0.05 + gas
                          </p>
                      </dd>
                      <dt class="mb-4">
                          <h3 class="text-xl text-gray-600 dark:text-gray-200 flex-grow font-hemi">
                              How Many?
                          </h3>
                      </dt>
                      <dd class="mb-16">
                          <p className="text-lg text-gray-600 dark:text-gray-500 flex-grow font-hemi">
                            10,000 Hustle Club members will be generated. 200 will be kept back for giveaways and partnerships, but the team will mint like everyone else.
                          </p>
                      </dd>
                      <dt class="mb-4">
                          <h3 class="text-xl text-gray-600 dark:text-gray-200 flex-grow font-hemi">
                              Wen launch?
                          </h3>
                      </dt>
                      <dd class="mb-16">
                          <p className="text-lg text-gray-600 dark:text-gray-500 flex-grow font-hemi">
                            Key Dates will be announced soon
                          </p>
                          {/* <p className="text-lg text-gray-600 dark:text-gray-500 flex-grow font-hemi">
                            Pre-sale: 9th November 2021
                          </p>
                          <p className="text-lg text-gray-600 dark:text-gray-500 flex-grow font-hemi">
                            Public Sale: 10th November 2021
                          </p> */}
                      </dd>
                      <dt class="mb-4">
                          <h3 class="text-xl text-gray-600 dark:text-gray-200 flex-grow font-hemi">
                            Wat IP?
                          </h3>
                      </dt>
                      <dd class="mb-16">
                          <p className="text-lg text-gray-600 dark:text-gray-500 flex-grow font-hemi">
                            NFT holders own the right to their IP. You can do with it what you want!
                          </p>
                      </dd>
                      <dt class="mb-4">
                          <h3 class="text-xl text-gray-600 dark:text-gray-200 flex-grow font-hemi">
                            Looks rare?
                          </h3>
                      </dt>
                      <dd class="mb-16">
                          <p className="text-lg text-gray-600 dark:text-gray-500 flex-grow font-hemi">
                            Might be?
                          </p>
                      </dd>

                  </dl>
              </div>
          </div>


        </div>

      </div>
    </section>
  );
}

export default FAQ;
